<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Your Name & Goal
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        Deadline
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        Investment
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
        <ContractStepOne v-on:setStep="setStep(2)"></ContractStepOne>
        <ContractStepTwo v-on:setStep="setStep(3)"></ContractStepTwo>
        <ContractStepThree v-on:setStep="setStep(1)"></ContractStepThree>

    </v-stepper-items>
  </v-stepper>
</template>

<script>
import ContractStepOne from './ContractStepOne.vue';
import ContractStepTwo from './ContractStepTwo.vue';
import ContractStepThree from './ContractStepThree.vue';

export default {
  components: { ContractStepOne, ContractStepTwo, ContractStepThree },
  name: 'Stepper',
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      first: 0,
      e1: 1,
    };
  },
  methods: {
    setStep(value) {
      this.e1 = value;
      console.log('event fired and received: ', this.e1);
    },
  },
};
</script>
