<template>
  <v-stepper-content step="2">
    <v-date-picker
      v-model="date"
      @input="set(date)"
      :landscape="true"
      header-color="primary"
      width="290"
    ></v-date-picker>
    <v-btn color="primary" @click="$emit('setStep')"> Continue</v-btn>

    <v-btn text>
      Cancel
    </v-btn>
  </v-stepper-content>
</template>
<script>
export default {
  name: 'ContractStepTwo',
  data() {
    return {};
  },
  methods: {
    set() {
      return 1;
    },
  },
  computed: {
    date: {
      get() {
        return this.$store.state.user.currentGoal.date;
      },
      set(value) {
        const goalTmp = this.$store.state.user.currentGoal;
        goalTmp.date = value;
        this.$store.dispatch('updateCurrentGoal', goalTmp);
      },
    },
  },
};
</script>
