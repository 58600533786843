<template>
  <v-container>
    <v-row> <v-col class="text-center mb-6" cols="2"></v-col></v-row>
    <v-row>
      <v-col class="text-center mb-6" cols="2"></v-col>
      <v-col class="text-center mb-6" cols="8">
        <h1>INVEST IN YOURSELF & EARN PROFIT</h1>
        <!-- <h4>Is it hard for you
        to motivate yourself? Struggling to achieve some goals?
            Starting projects but never finising them?
            Use kurama to finally achieve things.
            Lock funds and only get them back when you prove yourself succesfull
          </h4>--></v-col
      >
      <v-col class="text-center mb-6" cols="2"></v-col>
    </v-row>
    <v-row class="text-center">
      <!--     <v-col class="mb-6"
      cols="6">

          <p></p>
          <p></p>
          <p></p>
          <h3>Step 1: Describe your goal. By writing it down chances
            are rising to finally achieve them.</h3>
            <p></p>
          <h3>Step 2: Set your timeframe. When do you want to be done? To learn a good habbit,
            28 days is a good start.
            An urgent deadline is knocking on the door? Better hurry up.</h3>
            <p></p>
          <h3>Step 3: Set your stake. This is your reward when finishing
            but your punishment when quitting</h3>

      </v-col> -->
      <v-col class="mb-2" cols="2"></v-col>
      <v-col class="mb-6 text-center" cols="8"
        ><v-card elevation="19" outlined color=""
          ><h2>Create your contract</h2>
          <stepper></stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-2" cols="2"></v-col>
      <v-col cols="8">
        <v-card class="mx-auto" elevation="19" outlined>
          <v-img
            class="contract_background"
            src="https://images.unsplash.com/photo-1476799342226-c8b98101c82d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80"
            height="100px"
            dark
            ><v-card-title class="justify-center contract-title" color=""
              >Contract</v-card-title
            ></v-img
          >
          <v-card-text class="contract-text">
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-flag</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      user.currentGoal.descriptionText
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.currentGoal.date }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-cash</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary">
                Sign
              </v-btn></v-card-actions
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="mb-2" cols="2"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Stepper from './Stepper.vue';

export default {
  components: { Stepper },
  data() {
    return {
      investment: {
        goal: '',
        deadline: '',
        amount: 0,
      },
    };
  },
  methods: {},
  computed: mapState(['user']),
};
</script>
