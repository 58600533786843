import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      name: '',
      address: 'no wallet connected yet',
      //  this will hold the investment in other poeples goals
      investments: [],
      // array of goals read from chain or moralis
      goals: [],
      // goal that the user creats at the moment
      currentGoal: { descriptionText: '', date: '' },
    },
  },
  mutations: {
    SET_NAME(state, value) {
      state.user.name = value;
    },
    SET_NEW_INVESTMENT(state, investment) {
      state.user.name.investments.push(investment);
    },
    SET_NEW_GOAL(state, goal) {
      state.user.currentGoal = goal;
      console.log(state.user.currentGoal);
    },
  },
  actions: {
    updateUserName({ state, commit }, value) {
      if (state.user) {
        commit('SET_NAME', value);
      }
    },
    updateCurrentGoal({ state, commit }, value) {
      if (state.user) {
        commit('SET_NEW_GOAL', value);
      }
    },
    createInvestment({ state, commit }, value) {
      if (state.user) {
        commit('SET_NEW_INVESTMENT', value);
      }
    },
  },
  getters: {},
});
