/* eslint-disable import/extensions */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import App from './App.vue';
import store from './store/store';
import Home from './components/Home.vue';
import InvestArea from './components/InvestArea.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css';
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/js/all.js';

Vue.use(VueRouter);
const routes = [
  { path: '/', component: Home },
  { path: '/home', component: Home },
  { path: '/invest', component: InvestArea },
];
const router = new VueRouter({
  routes,
});
Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#111',
          secondary: '#000',
          accent: '#eb6a3a',
          error: '#b71c1c',
        },
      },
    },
  }),
  components: { App },
  template: '<App/>',
});
