import { render, staticRenderFns } from "./ContractStepTwo.vue?vue&type=template&id=5b4b07d4&"
import script from "./ContractStepTwo.vue?vue&type=script&lang=js&"
export * from "./ContractStepTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VDatePicker,VStepperContent})
