<template>
  <v-stepper-content step="3">
    <v-slider
      v-model="ex3.val"
      :label="ex3.label"
      :thumb-color="ex3.color"
      :step="0.01"
      :max="3"
      thumb-label="always"
    ></v-slider>
    <v-btn color="primary" @click="$emit('setStep')">
      Continue
    </v-btn>

    <v-btn text>
      Cancel
    </v-btn>
  </v-stepper-content>
</template>
<script>
export default {
  name: 'ContractStepThree',
  data() {
    return {
      ex3: {
        label: 'Funds to lock (ETH)',
        val: 1,
        color: 'red',
      },
    };
  },
};
</script>
