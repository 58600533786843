<template>
  <div style="height: 100vh;">
    <v-container main-content fill-height>
      <v-row>
        <v-col></v-col>
        <v-col cols="12" sm="10">
          <v-card outlined color="" class="text-center title-card">
            <v-card-title class="justify-center text-title">KURAMA DAO</v-card-title>
            <v-card-subtitle class="text-center subtitle">
              Improve your ability to focus on goals & find new sources of
              motivation
              <!-- Change when product is in MVP mode

              Ninjutsu is the first project of the kuramaDAO to support individuals on their way of
              building a strong and powerful character by providing a contract based incentivisation
              platform-->
            </v-card-subtitle>
            <br>
            <br>
            <v-btn color="#ff6600" class="mr-5" href="https://medium.com/@kuramaDAO/litepaper-kurama-proof-of-challenge-b7ad6bba28c"
              >Read Litepaper</v-btn
            >
            <!-- <v-btn color="#ff6600" @click="$router.push('invest')">Join Discord</v-btn> -->
          <v-btn href="https://discord.gg/uu5cBgQdpn" color="#ff6600">Join Discord</v-btn>
          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-container>
    <v-container fluid class="second-content-area">
      <v-row>
        <v-col>
          <h2>Headline 2</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-card outlined
          style="text-align:center;overflow-y: auto;min-width:75%;
           min-height:400px;background: #ff9700!important;padding-top:10%">
            <v-icon large color="#000000">
              mdi-safe
            </v-icon>
            <v-card-title class="justify-center" style="">
              Goal Oriented contracts
            </v-card-title>
            <v-card-subtitle class="text-center" style="font-size: 0.9rem !important"
              >Create contracts that prove your commitment. By locking funds into a contract you
              have a stronger motivation to reach your goal. You create a written statement to work
              hard to achieve it.
            </v-card-subtitle>
            <v-btn href="" style="margin:25px;" color="#ff6600">coming soon</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined style="text-align:center;overflow-y: auto;min-width:75%;
           min-height:400px;background: #ff9700!important;padding-top:10%">
            <v-icon large color="#000000">
              mdi-bank-plus
            </v-icon>
            <v-card-title class="justify-center" style="">
              Earn Yield
            </v-card-title>
            <v-card-subtitle class="text-center" style="font-size: 0.9rem !important"
              >You can let your locked funds work in the background and earn a reward when your goal
              is reached. We provide yield because your hard earned money should work for you
              exactly as you work on yourself.
            </v-card-subtitle>
            <v-btn href="" style="margin:25px;" color="#ff6600">coming soon</v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined style="text-align:center;overflow-y: auto;min-width:75%;
           min-height:400px;background: #ff9700!important;padding-top:10%">
            <v-icon large color="#000000">
              mdi-lifebuoy
            </v-icon>
            <v-card-title class="justify-center" style="">Find Support </v-card-title>
            <v-card-subtitle class="text-center" style="font-size: 0.9rem !important">
              Tell others and see how they trust in your pledge by decreasing the value of your
              contract. Another incentive to reach your goal.<br><br>
            </v-card-subtitle>
            <v-btn href="" style="margin:25px;" color="#ff6600">coming soon</v-btn>
          </v-card>
        </v-col>
      </v-row></v-container
    >
    <v-divider></v-divider>
    <!--
    <v-container fluid class="third-content-area">
      <v-row>
        <v-col class="justify-center" cols="12" sm="12">
          <h2>What makes ninjutsu unique?</h2></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card outlined style="overflow-y: auto; height:100%">
            <v-card-subtitle class="text-center"
              >Technology should be serving you and that's what we want to achieve. Using smart
              contracts to your advantage and providing financial gains is a highly useful mechanism
              to not just grow personally but also monetary. The kuramaDAO aims to ignite your
              passion to progress as a human being. The first step is this commitment service.
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row></v-container
    >
    <v-container fluid class="second-content-area">
      <v-row>
        <v-col class="justify-center" cols="12" sm="12"> <h2>How does it work?</h2></v-col>
      </v-row>
      <v-row>
        <v-col class="justify-center" cols="12" sm="3">
          <v-card outlined style="overflow-y: auto; height:100%">
            <v-card-title class="justify-center" style=""> Create</v-card-title>
            <v-card-subtitle class="text-center"
              >Describe your goal, set a deadline and choose your investment. Once created the smart
              contract is deployed to the chain and proves your commitment.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined style="overflow-y: auto; height:100%">
            <v-card-title class="justify-center" style="">Verify </v-card-title>
            <v-card-subtitle class="text-center"
              >Decide if you trust yourself enough and verify by yourself or nominate friends to
              prove the fulfillment of your obligation. Got a trusted device or API? Let's connect
              it.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined style="overflow-y: auto; height:100%">
            <v-card-title class="justify-center" style="">Earn</v-card-title>
            <v-card-subtitle class="text-center">
              Once your achievement is verified your funds are released and you receive your initial
              investment plus up to 100% yield. Goal not reached? A small penalty of 5% is taken
              from your funds but you can use them in your next contract.
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined style="overflow-y: auto; height:100%">
            <v-card-title class="justify-center" style="">Help</v-card-title>
            <v-card-subtitle class="text-center">
              Want to help others to achieve their goals? Invest in their contract, help them
              succeed and earn with them
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row></v-container
    >-->
  </div>
</template>

<script>
export default {
  components: {},
  name: 'Home',
  data() {
    return {};
  },
  methods: {},
};
</script>
