<template>
  <v-stepper-content step="1">
    <v-text-field v-model="username" @input="set('huhu')" label="Username" outlined></v-text-field>
    <v-textarea
      v-model="currentGoal"
      @input="set('Joho')"
      outlined
      name="input-7-4"
      label="Describe your goal"
    >
    </v-textarea>
    <v-btn color="primary" @click="$emit('setStep')">
      Continue
    </v-btn>

    <v-btn text>
      Cancel
    </v-btn>
  </v-stepper-content>
</template>
<script>
export default {
  name: 'ContractStepOne',
  data() {
    return {};
  },
  methods: {
    set() {
      return 1;
    },
  },
  computed: {
    username: {
      get() {
        return this.$store.state.user.name;
      },
      set(value) {
        this.$store.dispatch('updateUserName', value);
        console.log(this.$store.state.user.name);
      },
    },
    currentGoal: {
      get() {
        return this.$store.state.user.currentGoal.descriptionText;
      },
      set(value) {
        const goalTmp = this.$store.state.user.currentGoal;
        goalTmp.descriptionText = value;
        this.$store.dispatch('updateCurrentGoal', goalTmp);
      },
    },
  },
};
</script>
