<template>
  <div class="MetaMaskLogin">
    <v-btn color="#ff6600" v-if="loginState == 0" v-on:click="login">connect wallet</v-btn>
    <v-chip class="addressChip" v-if="loginState == 1"
      >{{
        this.$store.state.user.address.substring(0, 4) +
          '......' +
          this.$store.state.user.address.substring(38, 42)
      }}
    </v-chip>

    <v-btn color="#ff6600" v-if="loginState == 1" v-on:click="logout">Logout</v-btn>
  </div>
</template>

<script>
const Moralis = require('moralis');

Moralis.initialize('wJO2tQw6wNj5FxBaKcBAuRvWuKing37HrdtRr1wz', 'YOUR_JAVASCRIPT_KEY');
// javascriptKey is required only if you have it on server.

Moralis.serverURL = 'https://y9pbeweuxfkg.moralis.io:2053/server';
export default {
  name: 'MetaMaskLogin',
  data() {
    return {
      loginState: 0,
      currentUser: {},
    };
  },
  methods: {
    checkLoginState() {
      this.currentUser = Moralis.User.current();
      if (this.currentUser) {
        this.$store.state.user.address = this.currentUser.get('ethAddress');
        this.loginState = 1;
      } else {
        this.loginState = 0;
      }
    },
    login() {
      Moralis.Web3.authenticate().then((user) => {
        this.$store.state.user.address = Moralis.User.current().get('ethAddress');
        this.$store.state.user.id = Moralis.User.current().get('objectId');
        console.log(user.get('ethAddress'));
        this.msg2 = user.get('ethAddress');
        this.loginState = 1;
        console.log(this.loginState);
      });
    },
    async logout() {
      await Moralis.User.logOut();
      this.loginState = 0;
      this.$store.state.user.address = 'no wallet connected';
    },
  },
  mounted() {
    this.checkLoginState();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('../assets/styles/styles.css');
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
