<template>
  <v-app>
    <v-app-bar flat app color="primary">
      <div class="d-flex align-center">
        <router-link to="/home"
          ><v-img
            alt="kurama Logo"
            class="shrink mr-2"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="160"
        /></router-link>
      </div>

      <v-spacer></v-spacer>
      <a href="https://discord.gg/uu5cBgQdpn"><i class="fab fa-discord fa-2x mr-5"  style="color:#ff6600"></i></a>
      <a href="https://medium.com/@kuramaDAO/litepaper-kurama-proof-of-challenge-b7ad6bba28c"><i class="fab fa-medium fa-2x" style="color:#ff6600"></i></a>
      <!-- <meta-mask-login></meta-mask-login> -->
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <!--Content starts-->
    <!--Content ends-->
  </v-app>
</template>
<style>
@import './assets/styles/styles.css';
</style>
<script>
import MetaMaskLogin from './components/MetaMaskLogin.vue';
import InvestArea from './components/InvestArea.vue';

export default {
  name: 'App',

  components: {
    // add Metamask Login Component again to activate login
    // eslint-disable-next-line vue/no-unused-components
    InvestArea,
  },

  data: () => ({
    MetaMaskLogin, //
  }),
};
</script>
